import * as React from "react";
// import { Button } from "react-aria-components";
// import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

// import { Link } from "react-router-dom";
// import useSWR from "swr";
// import { useReadLocalStorage } from "usehooks-ts";
// import { z } from "zod";
import AppHeader from "~/components/AppHeader";
// import { fetcher } from "~/hooks/useFetcher";

// interface IMarketIntelligencePageProps {}

// const marketIntelligences = [
//   {
//     label: "Transparency Awards",
//     to: "Transparency Awards",
//     total: 3062,
//   },
//   {
//     label: "Thought Pieces",
//     to: "Thought Pieces",
//     total: 3062,
//   },
//   {
//     label: "Governance Guidelines",
//     to: "Governance Guidelines",
//     total: 3062,
//   },
//   {
//     label: "ESG Framework",
//     to: "ESG Framework",
//     total: 3062,
//   },
//   {
//     label: "Investissors Voting Guidelines",
//     to: "Investissors Voting Guidelines",
//     total: 3062,
//   },
// ];

const MarketIntelligencePage: React.FunctionComponent = () => {
  return (
    <>
      <AppHeader />
      <Outlet />
    </>
  );
};

export default MarketIntelligencePage;
